body {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.floating-bar {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  border-radius: 15px !important;
  display: flex;
  flex-direction: column;
  padding: 7px;
  background-color: #121212;
  width: fit-content;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .floating-bar {
    display: flex;
    flex-direction: row;
    bottom: 0;
    left: 50%;
    top: auto;
    transform: translateX(-50%);
  }
}

@media (max-width: 344px) {
  .floating-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 1rem;
    left: 0;
    top: auto;
    transform: translateY(0);
    width: auto;
  }
}