.terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);

    &::before {
        content: "\2022 \2022 \2022";
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        background-color: rgb(58, 58, 58);
        color: #c2c3c4;
        width: 100%;
        font-size: 2rem;
        line-height: 0;
        padding: 14px 0;
        text-indent: 4px;
        text-align: left;
    }

    &::after {
        content: "Dlpz";
        position: absolute;
        top: 0;
        right: 12px;
        height: 5px;
        background-color: rgb(58, 58, 58);
        color: #c2c3c4;
        font-size: 1rem;
        line-height: 0;
        padding: 14px 0;
        text-indent: 4px;
        text-align: right;
        font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    }

    pre {
        font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
        color: white;
        padding: 0 1rem 1rem;
        margin: 0;
        text-align: left;
    }

    span {
        animation: blinker 1s linear infinite;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}